<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Compare the crystal structures of cesium chloride and tungsten; then, indicate whether each
        statement is true or false. Explain why the false statements are incorrect.
      </p>

      <p class="mb-5">
        <v-img style="max-width: 500px" src="/img/assignments/UofA/q1_3.png" />
      </p>

      <p class="mb-2">a) Cesium chloride has a body-centered cubic lattice.</p>

      <v-radio-group v-model="inputs.tf1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Explanation (if false):</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.c1" outlined />
      </p>

      <p class="mb-2">b) Cesium chloride has a primitive cubic lattice.</p>

      <v-radio-group v-model="inputs.tf2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Explanation (if false):</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.c2" outlined />
      </p>

      <p class="mb-2">c) Tungsten has a body-centered cubic lattice.</p>

      <v-radio-group v-model="inputs.tf3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Explanation (if false):</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.c3" outlined />
      </p>

      <p class="mb-2">d) Tungsten has a primitive cubic lattice.</p>

      <v-radio-group v-model="inputs.tf4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Explanation (if false):</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.c4" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUofA103SandboxQ1_3',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        tf1: null,
        c1: null,
        tf2: null,
        c2: null,
        tf3: null,
        c3: null,
        tf4: null,
        c4: null,
      },
      options: [
        {
          text: 'True',
          value: 'true',
        },
        {
          text: 'False',
          value: 'false',
        },
      ],
    };
  },
};
</script>
